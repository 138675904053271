import React from 'react';
// material
import { Button, Card, CardHeader, CardContent, Divider, Modal } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2';
import CloseIcon from '@mui/icons-material/Close';
// components
import Page from '../../componentes/Page';
import Iconify from '../../componentes/Iconify';
import BusqForm from './form';
import RTabla from '../../componentes/tabla';
import CONFIG from '../../CONFIG.json';
import { buildBody, isEmpty, workWithLocalStorage, getUniqueKey, compruebaForm } from '../../auxiliares/funciones';
import { getProvinces } from '../../auxiliares/maestros';

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    minWidth: '750px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

export default function Index(props) {
  const [lista, setLista] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());
  const [provincias, setProvincias] = React.useState([]);

    const campos = ['texto','provincia','notas','fechaAlta','estado','fechaFin','cps','resultados','nuevos'];
    const cabecera = ['Texto','Provincia','Anotación','Alta','Estado','F. Finaliz.','CP Trat.','Resultados','Nuevos'];
    const usuario = workWithLocalStorage('get','usuario',null);

    React.useEffect(() => {
        async function cargaInicial() {
            if (!cargado) {
                setProvincias(await getProvinces(usuario.token));
                const body = {};
                // eslint-disable-next-line react-hooks/exhaustive-deps
                const bodyJson = JSON.stringify(buildBody(usuario.token, body))
                fetch(`${CONFIG.api}search/list`, {
                    method: 'POST',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
                })
                    .then(res => res.json())
                    .then((result) => {
                        if (result.error === 0 && !isEmpty(result.data)) {
                            setLista(result.data);
                        }
                        setKey(getUniqueKey());
                        // eslint-disable-next-line react/prop-types
                        props.showLoader(false);
                        setCargado(true);
                    })
            }
        }
        cargaInicial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cargado]);

    const Actualiza = () =>{
      // eslint-disable-next-line react/prop-types
      props.showLoader(true); 
      // setCargado(false);
      setCargado(false)
    }

    const FormBus = {provincia: "", texto: "", notas: ""};

    const validaParar = (obj) => obj.estado === 'En Proceso';
    const validaExportar = (obj) => obj.resultados > 0;
    const validaBorrar = (obj) => obj.estado !== 'En Proceso';
    const [modalOpen, setModalOpen] = React.useState(false);
    const [busqueda, setBusqueda] = React.useState(FormBus);
    const cierraModal = () => setModalOpen(false);
    const abreModal = () => setModalOpen(true);
    const obligatorios = ['provincia','texto'];

    const AltaBusqueda = () =>{
        setBusqueda(FormBus);
        abreModal();
    }

    const actualizaCampo = (input) =>{
        const nombre = input.target.name
        const valor = input.target.value
        const comA = busqueda;
        comA[nombre] = valor
        setBusqueda(comA);
    }

    const GrabarBus = () =>{
        const valido = compruebaForm(FormBus,busqueda,obligatorios);
        if(valido){
            // eslint-disable-next-line react/prop-types
            props.showLoader(true);
            const body = busqueda;
            body.idUsuarioAlta = usuario.id;
            const bodyJson = JSON.stringify(buildBody(usuario.token,body))
            fetch(`${CONFIG.api}search/add`,{
                    method: 'PUT',
                    body: bodyJson,
                    headers: {'Content-Type': 'application/json'}
            })
            .then(res => res.json())
            .then((result) => {
                let msg ='Búsqueda grabada!';
                if (result.error === 0) {
                    cierraModal();
                    setCargado(false);
                }else{
                    props.showLoader(false);
                    msg = result.errorMessage;
                }
                Swal.fire({
                    title: msg,
                    buttons: [{label: 'Ok'}]
                })
            })
        }else{
            Swal.fire({
                title: 'Revisa los campos con *',
                buttons: [{label: 'Ok'}]
            })
        }
    }

    const exportaResultados = (obj) =>{
        props.showLoader(true);
        const body = { id: obj.id };
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}search/export`,{
            method: 'PUT',
            body: bodyJson,
            headers: {'Content-Type': "application/json"},
            responseType: "arraybuffer"
        })
            // .then((response) => {
            .then(res => res.blob())
            .then((blob) =>{
                if(blob.type !== 'application/json'){
                    const file = window.URL.createObjectURL(new Blob([blob]));
                    const fileLink = document.createElement('a');
                    fileLink.href = file;
                    fileLink.setAttribute('download', `${obj.provincia} ${obj.texto}.xlsx`);
                    fileLink.setAttribute('target', '_blank');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    fileLink.remove();
                }else{
                    Swal.fire({
                        title: 'No hay registros con ese filtro',
                        buttons: [{label: 'Ok'}]
                    })
                }
                // eslint-disable-next-line react/prop-types
                props.showLoader(false);
            })
    }

    const Parar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true); 
        const body = {
          id: obj.id
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}search/stop`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Busqueda parada!';
              if (result.error === 0) {    
                setLista([]);               
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    const Borrar = (obj) =>{
        // eslint-disable-next-line react/prop-types
        props.showLoader(true);
        const body = {
          id: obj.id
        }
        const bodyJson = JSON.stringify(buildBody(usuario.token,body))
        fetch(`${CONFIG.api}search/delete`,{
                  method: 'POST',
                  body: bodyJson,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            let msg ='Busqueda borrada!';
              if (result.error === 0) {
                setLista([]);
                setCargado(false);
              }else{
                msg = result.errorMessage;
              }
              Swal.fire({
                title: msg,
                buttons: [{label: 'Ok'}]
              })
        })
    }

    const theme = useTheme();
    const acciones = [
      {
        color:'error',
        nombre:'Parar',
        icono:<i />,
        funcion: Parar,
        estilo: {},
        condition: validaParar
      },
      {
        color:'warning',
        nombre:'Borrar',
        icono:<i />,
        funcion: Borrar,
        estilo: {},
        condition: validaBorrar
      },
      {
        color:'success',
        nombre:'Exportar',
        icono:<i />,
        funcion: exportaResultados,
        estilo: {},
        condition: validaExportar
      }
    ]

  return (    
    <Page title="Empresas" key={lista.length}>
        <Card  sx={{ maxWidth: '100% !important'}}>
          <CardHeader action={<>
                                <Button variant="contained" color="success" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>Actualiza()}>
                                  Actualizar
                                </Button>
                                <Button variant="contained" color="primary" sx={{ color: 'white', marginLeft: '15px'}} to="#" startIcon={<Iconify icon="eva:refresh-fill" />}
                                onClick={()=>AltaBusqueda()}>
                                  Nueva Búsqueda
                                </Button>
                              </>}
                  title="Búsquedas"
          />
          <Divider style={{ margin: '15px'}} />
          <CardContent>
            <RTabla key={key} lista={lista} campos={campos} cabecera={cabecera} acciones={acciones}/>
          </CardContent>
        </Card>
        <Modal open={modalOpen} onClose={cierraModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Card sx={style}>
                    <CardHeader title="Nueva Búsqueda"
                    action={<>
                        <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:edit-outline" />}
                        onClick={()=>GrabarBus()}>
                            Grabar
                        </Button>
                        <CloseIcon fontSize="inherit" onClick={() => cierraModal() }/>
                    </>}/>
                    <Divider style={{ margin: '15px'}} />
                    <CardContent>
                        <BusqForm showLoader={props.showLoader} provincias={provincias} busqueda={busqueda} actualizaCampo={actualizaCampo}/>
                    </CardContent>
                </Card>
            </Modal>
    </Page>
  );
}
