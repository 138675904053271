import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../componentes/Page';
import Logo from '../componentes/Logo';
// sections
import LoginForm from '../componentes/forms/LoginForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [loader, setLoader] = React.useState(false);

  const mdUp = useResponsive('up', 'md');

    return (
      <Page title="Login">
        <Backdrop
          sx={{ color: '#103996', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <RootStyle>

          <Container maxWidth="sm">
            <ContentStyle>
              <Typography variant="h4" gutterBottom>
                Bot Crawler
              </Typography>

              <LoginForm setLoader={setLoader}/>

            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
    );
}
