// component
import Iconify from '../../componentes/Iconify';

import { workWithLocalStorage } from '../../auxiliares/funciones';

// eslint-disable-next-line
import Busquedas from '../../paginas/busquedas';
// eslint-disable-next-line
import Empresas from '../../paginas/empresas';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const user = workWithLocalStorage('get','usuario',null);

const menuConfig = [
  {
    id: 1,
    title: 'Busquedas',
    path: '#',
    perfil: [0,1],
    icon: getIcon('eva:people-fill'),
    component: Busquedas,
    params: {}
  },
  {
    id: 2,
    title: 'Empresas',
    path: '#',
    perfil: [0,1],
    icon: getIcon('eva:people-fill'),
    component: Empresas,
    params: {}
  }
];

const menuFilter = () => {
  if(user !== undefined){
    // eslint-disable-next-line no-plusplus
    for(let i=menuConfig.length-1; i>=0;i--){
      if(menuConfig[i].children!==undefined){
        // eslint-disable-next-line no-plusplus
        for(let f=menuConfig[i].children.length-1; f>=0;f--){
            if(!menuConfig[i].children[f].perfil.includes(user.idPerfil)){
              menuConfig[i].children.splice(f,1);
            }
        }
      }else if(!menuConfig[i].perfil.includes(user.idPerfil)){
          menuConfig.splice(i,1);
        }
    }
  }
  return menuConfig;
}

const menu  = menuFilter();

export default menu;
