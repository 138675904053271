import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer, Typography } from '@mui/material';
// hooks
import useResponsive from '../../componentes/useResponsive';
// components
import Logo from '../../componentes/Logo';
import Scrollbar from '../../componentes/Scrollbar';
import MenuSection from './MenuSection';
//
import menuConfig from './MenuConfig';
import { workWithLocalStorage } from '../../auxiliares/funciones';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

// eslint-disable-next-line react/prop-types
export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, setTabs }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const user = workWithLocalStorage('get','usuario',null);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, paddingBottom: 0, display: 'inline-flex' }}>
        <Typography variant="body" sx={{ paddingTop: '7px', paddingLeft: '7px', color: '#164baf'}}>Bot Crawler</Typography>
      </Box>

      <Box sx={{ px: 2.5, display: 'inline-flex' }}>
        <Typography variant="body">{user.nombre}</Typography>
      </Box>

      <MenuSection setTabs={setTabs} menuConfig={menuConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
